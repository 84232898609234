import {
	Box,
	Button,
	FormControl,
	FormLabel,
	HStack,
	Input,
	SimpleGrid,
	Text,
	VStack,
	useToast,
} from '@chakra-ui/react';
import { Coin, Target, UserCircle } from 'tabler-icons-react';
import React, { FunctionComponent, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import useAuth, { User } from '../useAuth';

import { Feature } from './Feature';
import { GET_CUSTOMER } from '../../queries/getCustomer';
import HeroContainer from '../Containers/HeroContainer';
import Layout from '../Base/Layout';

const SUBMIT_REFFERAL_FORM = gql`
	mutation MyMutation(
		$email1: String
		$email2: String
		$adminEmail: String
		$fullName1: String
		$fullName2: String
		$phone1: String
		$phone2: String
	) {
		submitRefferalFormCF(
			input: {
				submissionId: "622"
				email1: $email1
				fullName1: $fullName1
				fullName2: $fullName2
				phone1: $phone1
				phone2: $phone2
				email2: $email2
				adminEmail: $adminEmail
			}
		) {
			fullName1
			fullName2
			email1
			email2
			phone1
			phone2
			adminEmail
			errors
			successMessage
		}
	}
`;

const RefferalLoggedIn: FunctionComponent = () => {
	const { user } = useAuth();
	const { id } = user as User;
	const customer = useQuery(GET_CUSTOMER, {
		variables: { id },
	});

	const [submitContactForm, { loading, error }] =
		useMutation(SUBMIT_REFFERAL_FORM);
	var toast = useToast();

	const [formValues, setFormValues] = useState({
		fullName2: '',
		email2: '',
		phone: '',
	});

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleFormData = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// Use the submitContactForm mutation to send the data to the server
		try {
			const { data } = await submitContactForm({
				variables: {
					// i made this up
					submissionId: '622',

					// 1 for logged in user who is reffering
					email1: user?.email,
					fullName1: `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
					phone1: customer.data?.customer.shipping.phone || 'No phone info',

					// 2 for reffered person
					fullName2: formValues.fullName2,
					phone2: formValues.phone,
					email2: formValues.email2,

					// admin email different for every website, can be hardcoded but also there is info on this through wp
					adminEmail: 'info@doctormedica.co',
				},
			});

			if (!error) {
				toast({
					status: 'success',
					title:
						'Your refferal went through! Please check your email for further details.',
				});
			} else {
				toast({
					status: 'error',
					title: 'There was an error. Please try again later.',
				});
			}
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	};

	return (
		<Layout>
			<HeroContainer
				height='460px'
				is404={false}
				backgroundPosition='right'
				heroTitle="Maximize Benefits Through Doctor Medica's Referral Program"
				heroSubTitle='Enhance savings and support colleagues in the medical and aesthetic fields.'
				heroImage='https://shop.doctormedica.co/wp-content/uploads/2023/12/dm-referal-hero.webp'
				arrowImage='/remixiconslinesystemarrowdownsline2.svg'
			// button
			// buttonHref='https://www.doctormedica.co/sign-in'
			// buttonText='Log In And Refer Your Colleague'
			/>
			<Box
				my={{ base: 10, md: 20 }}
				mx={{ base: 4, md: 'auto' }}
				id='anchorlink'
				maxW='1280px'
				py={{ base: 6, md: 12 }}
				px={{ base: 4, md: 14 }}
				borderRadius='16px'
				bg='#F8F1F0'
				display='flex'
				flexDirection={{ base: 'column', md: 'row' }}
				alignItems='stretch'
				gap={{ base: 6, md: 0 }}>
				<Box
					flexDir='column'
					alignItems='flex-start'
					flex={1}>
					<VStack
						gap={0}
						alignItems='flex-start'>
						<HStack
							flexFlow={{ base: 'column', lg: 'row' }}
							gap='40px'
							alignItems='center'>
							<Box w='100%'>
								<Box
									bg='#B97230'
									width='24px'
									height='2px'></Box>
								<Text
									pt={10}
									fontSize='md'
									fontWeight='400'
									color='#18181B'>
									REFER A COLLEAGUE
								</Text>
								<Text
									textTransform='capitalize'
									color='#120B0C'
									fontSize={{ base: '30px', md: '36px' }}
									fontWeight='400'
									pt={0.5}>
									Earn $350 Account Credit
								</Text>
							</Box>
						</HStack>
						<SimpleGrid
							py={8}
							row={{ base: 1, md: 3 }}
							spacing={{ base: 3.5, md: 8 }}>
							<Feature
								icon={<Coin />}
								title={
									<Text>
										<b>$350</b> account credit for each successful referral.
									</Text>
								}
								align={{ base: 'flex-start', md: 'center' }}
								direction={{ base: 'column', md: 'row' }}
							/>
							<Feature
								icon={<UserCircle />}
								title={
									<Text>
										Additional <b>$150</b> for every fifth successful referral.
									</Text>
								}
								align={{ base: 'flex-start', md: 'center' }}
								direction={{ base: 'column', md: 'row' }}
							/>
							<Feature
								icon={<Target />}
								title={
									<Text>
										Your referral will receive a <b>$50</b> account credit.
									</Text>
								}
								align={{ base: 'flex-start', md: 'center' }}
								direction={{ base: 'column', md: 'row' }}
							/>
						</SimpleGrid>

						<Text
							color='#120B0C'
							fontSize='sm'
							fontWeight='400'
							lineHeight='150%'>
							There is no limit on the number of referrals you can make. The
							referral benefit is activated once the referee completes $2000 in
							successful orders.
						</Text>

					</VStack>
				</Box>
				<Box flex={1}>
					<form onSubmit={handleFormData}>
						<VStack
							alignItems='flex-start'
							gap={8}>
							<Box w='100%'>
								<Text
									color='#8C8C8C'
									fontWeight='400'
									fontSize='16px'
									pt={2}
									pb={4}
									borderBottom='1px solid #595959'>
									Your Colleague’s Information
								</Text>
							</Box>

							<FormControl>
								<FormLabel
									fontSize={'md'}
									pb={3}
									mb={0}>
									Colleague’s Full Name
								</FormLabel>
								<Input
									id='input'
									name='fullName2'
									bg='#fff'
									_focusVisible={{ borderColor: '#D28C49' }}
									height='56px'
									border='1px solid #27272A '
									type='text'
									minLength={3}
									required
									value={formValues.fullName2}
									onChange={handleInputChange}></Input>
							</FormControl>

							<FormControl>
								<FormLabel
									fontSize={'md'}
									pb={3}
									mb={0}>
									Colleague’s Email address
								</FormLabel>
								<Input
									id='input'
									name='email2'
									bg='#fff'
									_focusVisible={{ borderColor: '#D28C49' }}
									height='56px'
									border='1px solid #27272A '
									type='email'
									minLength={8}
									required
									value={formValues.email2}
									onChange={handleInputChange}></Input>
							</FormControl>

							<FormControl>
								<FormLabel
									fontSize={'md'}
									pb={3}
									mb={0}>
									Colleague’s Phone number
								</FormLabel>
								<Input
									id='input'
									name='phone'
									bg='#fff'
									_focusVisible={{ borderColor: '#D28C49' }}
									height='56px'
									border='1px solid #27272A '
									type='text'
									minLength={6}
									required
									value={formValues.phone}
									onChange={handleInputChange}></Input>
							</FormControl>

							<HStack
								w='100%'
								alignItems='flex-start'
								justifyContent='flex-start'
								mt='24px'>
								<Button
									fontSize='14px'
									fontWeight={'700'}
									variant='outline'
									type='submit'
									w={{ base: '100%', md: 'auto' }}>
									{loading ? 'LOADING...' : 'REFER AND EARN REWARDS'}
								</Button>
							</HStack>
						</VStack>
					</form>
				</Box>
			</Box>
		</Layout>
	);
};

export default RefferalLoggedIn;
